import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
} from 'reactstrap';

//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// users
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { getLogedinUser } from '../helpers/commonFunctions';

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  let authUser = getLogedinUser();
  const [menu, setMenu] = useState(false);
  const [email, setEmail] = useState();

  const [username, setusername] = useState('Admin');

  useEffect(() => {
    if (authUser) {
      setusername(authUser.name);
    }
  }, [props.success]);
  // const handleCopy = () => {
  //   navigator.clipboard.writeText(authUser?.deepLink);
  //   toast.success('Copy Link Successfully');
  //   setShowShareLinkModel(false);
  // };

  const logout = () => {
    window.localStorage.removeItem('authUser');
    props.history.push('/login');
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={user4}
            alt="Header Avatar"
          /> */}
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
            {username}
          </span>{' '}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props.t("View Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/">
            <i className="uil uil-wallet font-size-18 align-middle me-1 text-muted"></i>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Settings")}
            <span className="badge bg-soft-success rounded-pill mt-1 ms-2">03</span>
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" /> */}
          <Link className="dropdown-item" to="/confrim-change-password">
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t('Change Password')}</span>
          </Link>
          {/* <div
            className="dropdown-item"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowShareLinkModel(!showShareLinkModel)}
          >
            <i className="uil uil-link-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t('Share Link')}</span>
          </div> */}
          <Link className="dropdown-item" to="/#" onClick={() => logout()}>
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      {/* <Modal
        isOpen={showShareLinkModel}
        toggle={() => {
          setShowShareLinkModel(!showShareLinkModel);
        }}
      >
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            // handleValidSubmit(e, v);
          }}
        >
          <div className="modal-header">
            <h6 className="modal-title mt-0" id="myModalLabel">
              Share {authUser?.name}
            </h6>
            <button
              type="button"
              onClick={() => {
                setShowShareLinkModel(!showShareLinkModel);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                <div className="mb-3">
                  <AvField
                    name="Email"
                    // label="First Name"
                    type="text"
                    placeholder="Enter Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                handleCopy();
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Copy Link
            </button>
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
              disabled={!email}
            >
              Done
            </button>
          </div>
        </AvForm>
      </Modal> */}
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
